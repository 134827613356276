/* ===============================
 * Rodal v1.5.4 http://rodal.cn
 * =============================== */

import React from 'react';
import PropTypes from 'prop-types';

// env
const inBrowser = typeof window !== 'undefined';
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isIE9 = UA && UA.indexOf('msie 9.0') > 0;

const Dialog = props => {
    console.log("classname", { ...props })
    const className = `rodal-dialog rodal-${props.animation}-${props.animationType} ${props.classId}`;
    const CloseButton = props.showCloseButton ? <span className="rodal-close" onClick={props.onClose} /> : null;
    const { width, height, measure, duration, customStyles } = props;
    const style = {
        width: width + measure,
        height: height + measure,
        animationDuration: duration + 'ms',
        WebkitAnimationDuration: duration + 'ms'
    };
    const mergedStyles = { ...style, ...customStyles };

    return (
        <div ref={props.innerRef} style={mergedStyles} className={className}>
            {CloseButton}
            {props.children}
        </div>
    )
};

class Rodal extends React.Component {

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        measure: PropTypes.string,
        visible: PropTypes.bool,
        showMask: PropTypes.bool,
        closeMaskOnClick: PropTypes.bool,
        showCloseButton: PropTypes.bool,
        animation: PropTypes.string,
        duration: PropTypes.number,
        className: PropTypes.string,
        customStyles: PropTypes.object,
        customMaskStyles: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        onAnimationEnd: PropTypes.func
    };

    static defaultProps = {
        width: 400,
        height: 240,
        measure: 'px',
        visible: false,
        showMask: true,
        closeMaskOnClick: true,
        showCloseButton: true,
        animation: 'zoom',
        duration: 300,
        className: '',
        customStyles: {},
        customMaskStyles: {},
    };

    state = {
        isShow: false,
        animationType: 'leave'
    };

    constructor(props) {
        super(props)
        if (props.immediate) {
            this.state.isShow = true
            this.state.animationType = "enter"
        }
    }


    componentWillReceiveProps(nextProps) {
        if (!this.props.visible && nextProps.visible) {
            this.enter();
        } else if (this.props.visible && !nextProps.visible) {
            this.leave();
        }
    }

    componentWillUnmount() {
        if (this.props.visible) {
            this.leave();
        }
    }

    enter() {
        console.log("entering rodal")
        this.setState({
            isShow: true,
            animationType: 'enter',
            id: "dialog_" + Date.now()
        }, () => {
            if (this.props.idCallback)
                this.props.idCallback(this.state.id)
        });

        if (process.browser) {
            if (!document.getElementsByClassName("fixiPhone")[0]) {
                console.log("entro a top")
                Rodal.ygap = window.pageYOffset || document.documentElement.scrollTop
                document.body.style.top = `${-Rodal.ygap}px`
            }
            document.body.className += " fixiPhone"
        }
    }

    leave() {
        console.log("leaving rodal")
        this.setState(isIE9
            ? { isShow: false }
            : { animationType: 'leave' }
        );

        if (document.getElementsByClassName("fixiPhone").length>0) {
            console.log("remover")
            document.body.className = document.body.className.replace(/fixiPhone/g, "")
        }
        if (!document.getElementsByClassName("fixiPhone")[0]) {
            document.body.style.removeProperty("top")
            window.scrollTo(0, Rodal.ygap)
        }
    }

    animationEnd = () => {
        if (this.state.animationType === 'leave') {
            this.setState({ isShow: false });
        }

        const { onAnimationEnd } = this.props;
        onAnimationEnd && onAnimationEnd();
    }

    componentDidMount() {
        if (this.props.visible) {
            this.enter();
        }
        this.setState({
            id: "dialog_" + Date.now()
        }, () => {
            if (this.props.idCallback)
                this.props.idCallback(this.state.id)
        })
    }

    checkClick = (event) => {
        console.log("event rodal", event.target, document.getElementsByClassName(this.state.id), this.state.id)
        if (document.getElementsByClassName("autocomplete-suggestions").length == 0 && !this.moving && !document.getElementsByClassName(this.state.id)[0].contains(event.target) && document.getElementsByClassName("rc-time-picker-panel").length == 0)
            this.props.onClose()
    }

    render() {
        const { props, state } = this;
        const onClick = props.closeMaskOnClick ? this.checkClick : null;
        const style = {
            display: state.isShow ? '' : 'none',
            animationDuration: props.duration + 'ms',
            WebkitAnimationDuration: props.duration + 'ms'
        };

        console.log("this.state.id", this.state.id)

        return (
            <div style={style}
                className={"rodal rodal-fade-" + state.animationType + ' ' + props.className}
                onAnimationEnd={this.animationEnd}
            >
                {props.showMask &&
                    <div id={"mask-" + this.state.id} className="rodal-mask" style={props.customMaskStyles} onTouchMove={() => { this.moving = true }} onTouchStart={() => { this.moving = false }} onTouchEnd={onClick} onMouseDown={onClick}>
                        {this.state.isShow && <Dialog innerRef={ref => this.dialog = ref} classId={this.state.id} {...props} animationType={state.animationType}>
                            {props.children}
                        </Dialog>}
                    </div>
                    ||
                    <Dialog {...props} animationType={state.animationType}>
                        {props.children}
                    </Dialog>}
            </div>
        )
    }
}

export default Rodal;