import React from 'react'
import Rodal from './rodal';
import Head from 'next/head'
import '../styles/rodal.css'


const Modal = (props) => (
    <Rodal {...props} customStyles={{ ...props.style ,height: 'auto', bottom: 'auto', position: 'relative', maxWidth:"95%", boxSizing:"border-box" }}>
        {props.children}
    </Rodal>
)

export default Modal